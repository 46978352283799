<template>
 <MainWrapper :darkMode="darkMode">
  <Layout class="layout">
   <Header
    :style="{
          position: 'fixed',
          width: '100%',
          top: 0,
          [!rtl ? 'left' : 'right']: 0,
        }"
   >
    <a-row>
     <a-col
      :lg="!topMenu ? 4 : 3"
      :sm="6"
      :xs="12"
      class="align-center-v navbar-brand"
     >
      <sdButton
       v-if="!topMenu || innerWidth <= 991"
       type="white"
       @click="toggleCollapsed"
      >
       <img
        :src="
                  require(`../static/img/icon/${
                    collapsed ? 'right.svg' : 'left.svg'
                  }`)
                "
        alt="menu"
       />
      </sdButton>
      <router-link
       :class="
                topMenu && innerWidth > 991
                  ? 'striking-logo top-menu'
                  : 'striking-logo'
              "
       to="/"
      >
       <img
        :src="theme['image-large-logo']"
        alt="logo"
       />
      </router-link>
     </a-col>
     <a-col :lg="!topMenu ? 14 : 15" :md="8" :sm="0" :xs="0">
      <TopMenu v-if="topMenu && innerWidth > 991"/>
      <!--            <HeaderSearch v-else />-->
     </a-col>

     <a-col :lg="6" :md="10" :sm="0" :xs="0">
      <TopMenuSearch v-if="topMenu && innerWidth > 991">
       <div class="top-right-wrap d-flex">
        <!--                <a-->
        <!--                  :class="`${-->
        <!--                    activeSearch ? 'search-toggle active' : 'search-toggle'-->
        <!--                  }`"-->
        <!--                  @click="-->
        <!--                    () => {-->
        <!--                      toggleSearch();-->
        <!--                    }-->
        <!--                  "-->
        <!--                  href="#"-->
        <!--                >-->
        <!--                  <sdFeatherIcons type="search" />-->
        <!--                  <sdFeatherIcons type="x" />-->
        <!--                </a>-->
        <!--                <div-->
        <!--                  :class="`${-->
        <!--                    activeSearch-->
        <!--                      ? 'topMenu-search-form show'-->
        <!--                      : 'topMenu-search-form'-->
        <!--                  }`"-->
        <!--                >-->
        <!--                  <form action="">-->
        <!--                    <span class="search-icon">-->
        <!--                      <sdFeatherIcons type="search" />-->
        <!--                    </span>-->
        <!--                    <input type="text" name="search" />-->
        <!--                  </form>-->
        <!--                </div>-->
        <AuthInfo/>
       </div>
      </TopMenuSearch>
      <AuthInfo v-else/>
     </a-col>
     <a-col :md="0" :sm="18" :style="{ position: 'static' }" :xs="12">
      <div class="mobile-action">
       <!--       <a-->
       <!--        class="btn-search"-->
       <!--        href="#"-->
       <!--        @click="handleSearchHide(searchHide)"-->
       <!--       >-->
       <!--        <sdFeatherIcons v-if="searchHide" type="search"/>-->
       <!--        <sdFeatherIcons v-else type="x"/>-->
       <!--       </a>-->
       <a class="btn-auth" href="#" @click="onShowHide(hide)">
        <sdFeatherIcons type="more-vertical"/>
       </a>
      </div>
     </a-col>
    </a-row>
   </Header>
   <div class="header-more">
    <a-row>
     <a-col :md="0" :sm="24" :xs="24">
      <div class="small-screen-headerRight">
       <!--       <SmallScreenSearch :darkMode="darkMode" :hide="searchHide">-->
       <!--        <HeaderSearch/>-->
       <!--       </SmallScreenSearch>-->
       <SmallScreenAuthInfo :darkMode="darkMode" :hide="hide">
        <AuthInfo :rtl="rtl"/>
       </SmallScreenAuthInfo>
      </div>
     </a-col>
    </a-row>
   </div>
   <Layout>
    <template v-if="!topMenu || innerWidth <= 991">
     <Sider
      :collapsed="collapsed"
      :style="{
              margin: '63px 0 0 0',
              padding: '15px 15px 155px 15px',
              overflowY: 'auto',
              height: '100vh',
              position: 'fixed',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
      :theme="!darkMode ? 'light' : 'dark'"
      :width="280"
     >
      <perfect-scrollbar
       :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
      >
       <AsideItems
        :darkMode="darkMode"
        :events="onEventChange"
        :rtl="rtl"
        :toggleCollapsed="toggleCollapsedMobile"
        :topMenu="topMenu"
       />
      </perfect-scrollbar>
     </Sider>
    </template>
    <Layout class="atbd-main-layout">
     <Content>
      <Suspense>
       <template #default>
        <router-view></router-view>
       </template>
       <template #fallback>
        <div class="spin">
         <a-spin/>
        </div>
       </template>
      </Suspense>

      <Footer
       :style="{
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
              }"
       class="admin-footer"
      >
       <a-row>
        <a-col :md="24" :xs="24">
         <span class="admin-footer__copyright">{{ new Date().getFullYear() }} © E-Platform</span>
        </a-col>
        <!--        <a-col :md="12" :xs="24">-->
        <!--         <div class="admin-footer__links">-->
        <!--          <router-link to="/app/social/profile/overview"-->
        <!--          >Profile-->
        <!--          </router-link-->
        <!--          >-->
        <!--          <router-link to="/app/user/team">team</router-link>-->
        <!--          <router-link to="/app/contact/contact-grid"-->
        <!--          >Contacts-->
        <!--          </router-link-->
        <!--          >-->
        <!--         </div>-->
        <!--        </a-col>-->
       </a-row>
      </Footer>
     </Content>
    </Layout>
   </Layout>
  </Layout>
 </MainWrapper>
</template>
<script>
import {Layout} from "ant-design-vue";
import {MainWrapper, SmallScreenAuthInfo, TopMenuSearch,} from "./style";

import AuthInfo from "../components/utilities/auth-info/info";
import AsideItems from "./Aside";
import TopMenu from "./TopMenuItems";
import {PerfectScrollbar} from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {theme} from '../config/theme/themeVariables'

const {Header, Footer, Sider, Content} = Layout;


export default defineComponent({
 name: "WithAdminLayout",
 components: {
  MainWrapper,
  Header,
  Layout,
  Footer,
  Sider,
  Content,
  SmallScreenAuthInfo,
  TopMenuSearch,
  AuthInfo,
  AsideItems,
  TopMenu,
  PerfectScrollbar,
 },
 setup() {
  const collapsed = ref(false);
  const hide = ref(true);
  const searchHide = ref(true);
  const customizerAction = ref(false);
  const activeSearch = ref(false);

  const {dispatch, state} = useStore();

  const rtl = computed(() => state.themeLayout.rtlData);
  const darkMode = computed(() => state.themeLayout.data);
  const topMenu = computed(() => state.themeLayout.topMenu);

  collapsed.value = window.innerWidth <= 1200 && true;

  const toggleCollapsed = (e) => {
   e.preventDefault();
   collapsed.value = !collapsed.value;
  };
  const handleSearchHide = (search) => {
   searchHide.value = !search;
   hide.value = true;
  };
  const onShowHide = (h) => {
   hide.value = !h;
   searchHide.value = true;
  };

  const toggleSearch = () => {
   activeSearch.value = !activeSearch.value;
  };

  const toggleCollapsedMobile = () => {
   if (innerWidth <= 990) {
    collapsed.value = !collapsed.value;
   }
  };

  const onRtlChange = () => {
   const html = document.querySelector("html");
   html.setAttribute("dir", "rtl");
   dispatch("changeRtlMode", true);
  };

  const onLtrChange = () => {
   const html = document.querySelector("html");
   html.setAttribute("dir", "ltr");
   dispatch("changeRtlMode", false);
  };

  const modeChangeDark = () => {
   dispatch("changeLayoutMode", true);
  };

  const modeChangeLight = () => {
   dispatch("changeLayoutMode", false);
  };

  const modeChangeTopNav = () => {
   dispatch("changeMenuMode", true);
  };

  const modeChangeSideNav = () => {
   dispatch("changeMenuMode", false);
  };

  const showCustomizer = () => {
   customizerAction.value = !customizerAction.value;
  };

  const onEventChange = {
   onRtlChange,
   onLtrChange,
   modeChangeDark,
   modeChangeLight,
   modeChangeTopNav,
   modeChangeSideNav,
  };

  return {
   toggleCollapsed,
   handleSearchHide,
   toggleCollapsedMobile,
   onShowHide,
   collapsed,
   hide,
   searchHide,
   toggleSearch,
   customizerAction,
   activeSearch,
   innerWidth: window.innerWidth,
   rtl,
   darkMode,
   topMenu,
   onEventChange,
   ...onEventChange,
   showCustomizer,
   theme
  };
 },
});
</script>
<style>
.ps {
 height: calc(100vh - 100px);
}
</style>
